
import { Route, Routes } from 'react-router';
import "./index.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Home from './pages/home/home';

function App() {
  return (
    <>

      <Routes>

        <Route
          exact
          path="/"
          element={<Home />}
        />


      </Routes>
    </>
  );
}

export default App;
